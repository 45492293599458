import React, { Component } from 'react';
import { Container, NavDropdown, Navbar, Nav } from 'react-bootstrap';
import { Link, NavLink,useHistory } from 'react-router-dom';
import Logo from '../../../assets/logo.jpeg';
import './styles.scss';
/* import { useDispatch } from 'react-redux';
import { logout } from "../actions/auth"; */
class NavBarComponent extends Component {

  
  render() {

   /*  const dispatch = useDispatch();
    const history = useHistory();
    const logOut = async () => {
      dispatch(logout());
      history.push("/admin_login");
    }; */

    return (
      <Navbar expand="lg">
        <Container>
          {/* <Link className="navbar-brand" target="_blank" to="{https://parkmytruckusa.com}"><img className="logo" alt="logo" src={Logo} /></Link> */}
          <a className="navbar-brand" target="_blank" href="https://parkmytruckusa.com"><img className="logo" alt="logo" src={Logo} /></a>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="me-auto nav-bar-full">
              {/* <Nav><NavLink exact className="nav-link" to="/">Home</NavLink></Nav>
              <Nav><NavLink className="nav-link" to="/about-us">About us</NavLink></Nav>
              <Nav>
                <NavDropdown title="Locations" id="basic-nav-dropdown">
                  <NavDropdown.Item>
                    <NavLink className="nav-link" to="/about-us">Atlanta, GA</NavLink>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav> */}
              <Nav>
                {/* <NavLink className="btn btn-primary" to="https://parkmytruckusa.com/contact-us/">Contact us</NavLink> */}
                <a className="btn btn-primary" href="https://parkmytruckusa.com/contact-us/">Contact us</a> 
                </Nav>
            {/*   <Nav><button className="btn btn-primary">LogOut</button></Nav> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default NavBarComponent;