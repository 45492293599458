import React, { useEffect, useState  } from 'react';
import Logo from '../../assets/logo.jpeg';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AuthService from '../../service/auth.service';
import { Link ,useHistory} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Spinner } from "../../service/notiflix.service";



const schema = Yup.object({
  email: Yup.string().required("Email is required").email("Please enter a valid email"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(18, "Password must not exceed 18 characters"),
}).required();


function AdminLogin() {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  
  //const { isLoggedIn } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();


  useEffect(() => {
    (async () => {
      /* if (isLoggedIn) {
        history.push("/user_list");
      } */
    })();
  }, []);

   // On login

   const onLogin = async (_loginInputValues) => {
    Spinner.show();
    try {

      let _formData = new FormData();
     _formData.append("email", _loginInputValues.email);
     _formData.append("password", _loginInputValues.password);

      const _loginResp = await AuthService.login(_formData);

      if (_loginResp.status === "failure") {
        Spinner.hide();
        Alert.error(_loginResp.message);
        return false;
      }

      let _authUser = {
        email: _loginResp.data.email,
        id: _loginResp.data.id,
        role: _loginResp.data.role
       
      };

    //  await Promise.resolve(dispatch(login(_authUser)));
      Spinner.hide();
      history.push("/user_list");
      
    } catch (error) {
      Spinner.hide();
      Alert.error(error.message);
    }
  };
 
    return (
        <>
         <div className='flexloginauth'>
            <div className='loginclassregister'>
                <div className='logologin'><img className="logo" alt="logo" src={Logo} /></div>
                <form onSubmit={handleSubmit(onLogin)}>
                <h2>Sign in to your account</h2>
                <div className='inputflex'>
                  <label>Email Address</label>
                  <input 
                    {...register("email", { required: true })}
                    className="mt-1"
                    type="email"
                    placeholder="john@doe.com"
                    autoComplete="off"
                  
                  />
                  <div className="errorred">
                  {errors.email?.message}
                </div>

                </div>
                <div className='inputflex'>
                  <label>Password</label>
                  <input 
                    placeholder="Password"
                    {...register("password", {
                      required: true,
                    })}
                    className="mb-2 mt-4"
                    type="password"
                  />
                  <div className="errorred">
                  {errors.password?.message}
                </div>
                </div>
                <div className='inputflex forgotpassowrd'>
                <Link to="/forgot_password">
                Forgot password?
                </Link>
                </div>
                <div>
                  <button type="submit" className='buttonclass wfull'>Sign In</button>
                </div>
                </form>
            </div>
         </div>
        </>
    );
  

}  


export default AdminLogin;