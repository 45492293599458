import React, { Component, Fragment } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const phoneRegExp = /^\(?([0-9]{0,3})\)?[-. ]?([0-9]{0,3})[-. ]?([0-9]{0,4})$/;

const personalInfoSchema = Yup.object().shape({
  email_address: Yup.string()
    .email('Please enter Valid Email')
    .required('Please enter this field'),
  first_name: Yup.string()
    .required('Please enter this field'),
  last_name: Yup.string()
    .required('Please enter this field'),
  mobile_number: Yup.string()
    .typeError('Please enter only numbers')
    .matches(phoneRegExp, 'Please enter valid phone number')
    .required('Please enter this field')
});
class PersonalInfoForm extends Component {
  render() {
    const {
      registerIndex,
      changeRegisterIndex,
      totalSteps,
      submitPersonalInfo
    } = this.props;
    const formatPhoneNumber = (str) => {
      // Filter only numbers from the input
      let cleaned = ('' + str).replace(/\D/g, '');

      // Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return match[1] + '-' + match[2] + '-' + match[3]
      }
    };
    return (
      <Fragment>
        <Row>
          <Col sm="12">
            <h3 className="mb-4"><span className='step-count'>{`Step ${registerIndex}/${totalSteps} - `}</span> Personal Information:</h3>
            <div className="form">
              <Formik
                initialValues={{
                  email_address: '',
                  first_name: '',
                  last_name: '',
                  mobile_number: ''
                }}
                validationSchema={personalInfoSchema}
                onSubmit={values => {
                  submitPersonalInfo(values);
                  window.scrollTo(0, 0);
                  changeRegisterIndex(registerIndex + 1)
                }}
              >
                {({ handleChange, handleBlur, errors, touched, values }) => (
                  <Form noValidate>
                    <div className="form-group row" id="email_address">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Email Address:
                      </label>
                      <div className="col-sm-7">
                        <Field name="email_address"
                          type="email"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email_address}
                        />
                      </div>
                      {errors.email_address && touched.email_address ? (
                        <div className='validation-error col-12 text-right'>{errors.email_address}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="first_name">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        First Name:
                      </label>
                      <div className="col-sm-7">
                        <Field name="first_name"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.first_name}
                        />
                      </div>
                      {errors.first_name && touched.first_name ? (
                        <div className='validation-error col-12 text-right'>{errors.first_name}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="last_name">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Last Name:
                      </label>
                      <div className="col-sm-7">
                        <Field name="last_name"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.last_name}
                        />
                      </div>
                      {errors.last_name && touched.last_name ? (
                        <div className='validation-error col-12 text-right'>{errors.last_name}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="mobile_number">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Mobile Number:
                      </label>
                      <div className="col-sm-7">
                        <Field name="mobile_number"
                          type="text"
                          className="form-control"
                          maxLength="12"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={formatPhoneNumber(values.mobile_number)}
                        />
                      </div>
                      {errors.mobile_number && touched.mobile_number ? (
                        <div className='validation-error col-12 text-right '>{errors.mobile_number}</div>
                      ) : null}
                    </div>
                    <div className="actions text-center">
                      <Button onClick={_ => {
                        window.scrollTo(0, 0);
                        changeRegisterIndex(registerIndex - 1)
                      }} type="button" className="form-btn float-left"><BsChevronLeft /> Back</Button>
                      <Button type="submit" className="form-btn float-right">Next <BsChevronRight /></Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default PersonalInfoForm;
