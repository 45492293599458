import React, {useEffect, useState } from 'react';
import Logo from '../../assets/logo.jpeg';
import AuthService from '../../service/auth.service';
import { Link ,useHistory,useParams} from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Alert, Spinner } from "../../service/notiflix.service";
// import { useSelector } from "react-redux";

function ResetPassword() {

  const [passwordShown, setPasswordShown] = useState(false);
  const [, setVerifyEmail] = useState(true);
  const { token } = useParams();
 // const { isLoggedIn } = useSelector((state) => state.auth);
  const history = useHistory();

  const schema = Yup.object({
    newPassword: Yup.string()
      .required("New Password is required")
      // .min(6, "Password must be at least 6 characters")
      .max(18, "Password must not exceed 18 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),

    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      // .min(6, "Confirm Password must be at least 6 characters")
      .max(18, "Confirm Password must not exceed 18 characters")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Password and Confirm Password should be the same"
      ),
  }).required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

   /* Verify token by email */
  useEffect(() => {
    /* if (isLoggedIn) {
      navigate("/admin/dashboard");
      return false;
    } */
    (async () => {
      if (token !== null && token !== undefined && token !== "") {
        await onVerifyToken(token);
      }
    })();
  }, []);


  const onSubmit = (data) => {
    onResetPassword(data);
  };

  const onVerifyToken = async (_token) => {
    try {
      const response = await AuthService.verifyToken(_token);
     
      if (response.status === "failure") {
        Alert.error(response.message);
        history.push("/forgot_password");
      }
    
      setVerifyEmail(true);
    } catch (error) {
      Alert.error(error.message);
      history.push("/admin_login");
      setVerifyEmail(false);
    }
  };

  const onResetPassword = async (_formInput) => {
    Spinner.show();
    try {
 
      let _formData = new FormData();

      _formData.append("password",_formInput.newPassword);
      _formData.append("token", token);
    
      const response = await AuthService.resetPassword(_formData);

      if (response.status === "failure") {
        Spinner.hide();
        Alert.error(response.message);
        return false;
      }

      Alert.success(response.message);
      reset();
      history.push("/admin_login");
      Spinner.hide();

    } catch (error) {
      Spinner.hide();
      Alert.error(error.message);
    }
  };


     return (
        <>
           <div className='flexloginauth'>
              <div className='loginclassregister'>
                  <div className='logologin'><img className="logo" alt="logo" src={Logo} /></div>
                  <h2>Reset Password</h2>

                  <form onSubmit={handleSubmit(onSubmit)}>
                
                  <div className='inputflex'>
                    <label>New Password</label>

                    <input type='password'
                     name="newPassword"
                     className='mt-1 pr20' 
                     placeholder='New Password'
                     autoComplete="off"
                    {...register("newPassword", { required: true })}
                    />
                    <div className="errorred">
                      {errors.newPassword?.message}
                    </div>
                  </div>
                  <div className='inputflex'>
                    <label>Confirm Password</label>
                    <input type='password' 
                    className='mt-1 pr20' 
                    name="confirmPassword"
                    placeholder='Confirm Password'
                    autoComplete="off"
                    {...register("confirmPassword", { required: true })}
                    />  
                    <div className="errorred">
                      {errors.confirmPassword?.message}
                    </div>
                  </div>
                  <div className='mt30'>
                    <button type="submit" className='buttonclass wfull'>Reset Password</button>
                  </div>
                  </form>
              </div>
          </div>
        </>
    );
  }

export default ResetPassword;





