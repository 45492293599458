const InitialState = {
  startDate: new Date(),
  endDate: new Date(),
  email_address: '',
  first_name: '',
  last_name: '',
  mobile_number: '',
  registerIndex: 1,
  // Configurable 
  totalSteps: 7,
  // ends
  company_name:'',
  company_url: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zip: '',
  driver: null,
  driver_name: '',
  driver_licence_number: '',
  driver_licence_expiry: '',
  driver_address: '',
  truck: null,
  truck_register_number: '',
  truck_licence_expiry: '',
  truck_register_state: '',
  truck_color: '',
  insurance: null,
  insurance_company: '',
  insurance_policy_number:'',
  insurance_expiry:'',
  agreeTerms: false,
  signature: '',
  slotAvailable: false,
  loading: false,
  registrationSuccess: null,
  message: '',
  slotMsg: '',
  vehicleIdentification: '',
  vin: '',
  vehicleType: 'trailerOnly',
  vehiclePrice: 195,
  noOfDays: 1
};

const reducer = (state = InitialState, action = {}) => {
  const {
    type,
    value,
    field,
    registerIndex,
    slotMsg,
    slotAvailable,
    loading,
    registrationSuccess,
    email_address,
    first_name,
    last_name,
    driver_name,
    driver_licence_number,
    driver_licence_expiry,
    driver_address,
    truck_register_number,
    truck_licence_expiry,
    truck_register_state,
    truck_color,
    insurance_company,
    insurance_policy_number,
    insurance_expiry,
    mobile_number,
    company_name,
    company_url,
    address_1,
    address_2,
    city,
    state: stateName,
    zip,
    totalSteps,
    message,
  } = action;
  switch (type) {
    case 'SET_FIELDS':
      state[field] = value;
      return {
        ...state, 
      }
    case 'SHOW_LOADER':
      return {
        ...state,
        loading
      }
    case 'CHANGE_REGISTER_INDEX':
      return {
        ...state,
        registerIndex
      }
    case 'CHANGE_TOTAL_STEPS':
      return {
        ...state,
        totalSteps
      }
    case 'SET_SLOT_AVAILABLITY':
      return {
        ...state,
        slotAvailable,
        slotMsg,
        loading: false
      }
    case 'REGISTRATION_SUCCESS':
      return { ...state, registrationSuccess, message }
    case 'SUBMIT_COMPANY_INFO':
      return { ...state, company_name, company_url, address_1, address_2, city, state: stateName, zip }
    
    case 'SUBMIT_PERSONAL_INFO':
      const mobile = mobile_number.replaceAll(/-/ig, '');
      return {
        ...state,
        email_address,
        first_name,
        last_name,
        mobile_number: mobile
      }
    case 'SUBMIT_DRIVER_INFO':
      return {
        ...state,
        driver_name,
        driver_licence_number,
        driver_licence_expiry,
        driver_address,
      }
    case 'SUBMIT_TRUCK_INFO':
      return {
        ...state,
        truck_register_number,
        truck_licence_expiry,
        truck_register_state,
        truck_color,
      }
    case 'SUBMIT_INSURANCE_INFO':
      return {
        ...state,
        insurance_company,
        insurance_policy_number,
        insurance_expiry,
      }
    default:
      return state;
  }
}

export default reducer;