import StorageService from "./service/storage.service";


const CONFIG = {
  //BASE_URL: "../index.php/apis/web_api"
 //   BASE_URL: "http://18.191.255.223/index.php/apis/web_api/"
  BASE_URL: "https://api-reserved.parkmytruckusa.com/index.php/apis/web_api/"
};

export default CONFIG;
