import React, { Component, useEffect,useState } from 'react';
import userimg from '../../assets/profile.png';
import { Alert, Spinner } from "../../service/notiflix.service";
import { Link ,useHistory,useParams} from "react-router-dom";
import UserService from '../../service/user.service';
import moment from "moment";


const UserDetails = () => {
    let params = useParams();
    let userId = params ? params.id : null;
    let history = useHistory();

    const [userDetails, setUserDetails] = useState([]);

    // function handleClick() {
    //     history.push("/user_list");
    // }
    const handleClick = (() => {
        history.push("/user_list")
    })

    useEffect(() => {
        (async () => {
          await getUserDetails(userId);
        })();
      }, [userId]);

  const getUserDetails = async (userId) => {
    Spinner.show();
    try {
      let _response = await UserService.getUserDetails(userId);

      if (_response.status === "failure") {
        Spinner.hide();
        Alert.error(_response.message);
        return false;
      }
      Spinner.hide();
      setUserDetails(_response.data[0]);
      
    } catch (error) {
      Spinner.hide();
      Alert.error(error.message);
      return false;
    }
  };

  const formatPhoneNumber = (str) => {
    // Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    // Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3]
    }
  };


    return (
            <>
              
               <div className='container'>
              {/*  <div className='titlepage'>User Details</div> */}
               <div className='max-wdetails'>
                        <div class="row">
                        <div class="col-12">
                       <button onClick={handleClick} className='inlineflexbutton'>
                       <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
</svg>
 Back
                       </button>
                        </div>
                        <div class="col-12">
                            <div className='vehicl-details-title'>User Registration Details</div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                                 <div className='flexdetails'>
                                    <div className='flexdleft'>Name</div>
                                    <div className='flexdright'>{userDetails?.first_name && userDetails?.last_name ? userDetails.first_name+' '+userDetails.last_name:'--'}</div>
                                </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                                <div className='flexdetails'>
                                    <div className='flexdleft'>Email Address	</div>
                                    <div className='flexdright'>{userDetails?.email_address ? userDetails.email_address:'--'}</div>
                                </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                                <div className='flexdetails'>
                                    <div className='flexdleft'>Mobile Number</div>
                                    <div className='flexdright'>{userDetails?.mobile_number ? formatPhoneNumber(userDetails?.mobile_number) :'--'}</div>
                                </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                                  <div className='flexdetails'>
                                    <div className='flexdleft'>Company Name</div>
                                    <div className='flexdright'>{userDetails?.company_name ?userDetails.company_name:"--"}</div>
                                </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                                 <div className='flexdetails'>
                                    <div className='flexdleft'>Company URL</div>
                                    <div className='flexdright'>{userDetails?.company_url ? userDetails?.company_url : "--"}</div>
                                </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                                <div className='flexdetails'>
                                    <div className='flexdleft'>Address1	</div>
                                    <div className='flexdright'>{userDetails?.address_1 ? userDetails.address_1 : "--" }</div>
                                </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-4">
                                <div className='flexdetails'>
                                    <div className='flexdleft'>Address2	</div>
                                    <div className='flexdright'>{userDetails?.address_2 ? userDetails.address_2 : "--" }</div>
                                </div>
                        </div>
                       
                        <div class="col-12">
                            <div className='vehicl-details-title'>Vehicle Details</div>
                        </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                            <div className='flexdetails'>
                                <div className='flexdleft'>Driver Name</div>
                                <div className='flexdright'>{userDetails?.driver_name ? userDetails.driver_name : "--"}</div>
                            </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                <div className='flexdetails'>
                                    <div className='flexdleft'>Driver Address</div>
                                    <div className='flexdright'>{userDetails?.driver_address ? userDetails?.driver_address:'--'}</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                <div className='flexdetails'>
                                    <div className='flexdleft'>Driver Licence Expiry</div>
                                    <div className='flexdright'>{userDetails?.driver_licence_expiry ? userDetails?.driver_licence_expiry:'--'}</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                 <div className='flexdetails'>
                                    <div className='flexdleft'>Truck Color</div>
                                    <div className='flexdright'>{userDetails?.truck_color ? userDetails?.truck_color :'--'}</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                  <div className='flexdetails'>
                                    <div className='flexdleft'>Truck Register Number</div>
                                    <div className='flexdright'>{userDetails?.truck_register_number ? userDetails?.truck_register_number:'--' }</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                 <div className='flexdetails'>
                                    <div className='flexdleft'>Truck Licence Expiry</div>
                                    <div className='flexdright'>{userDetails?.truck_licence_expiry ? userDetails?.truck_licence_expiry:'--' }</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                 <div className='flexdetails'>
                                    <div className='flexdleft'>Truck Register State</div>
                                    <div className='flexdright'>{userDetails?.truck_register_state ? userDetails?.truck_register_state :'--'}</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                <div className='flexdetails'>
                                    <div className='flexdleft'>Insurance Company</div>
                                    <div className='flexdright'>{userDetails?.insurance_company ? userDetails?.insurance_company:'--' }</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                  <div className='flexdetails'>
                                    <div className='flexdleft'>Insurance Policy</div>
                                    <div className='flexdright'>{userDetails?.insurance_policy ? userDetails?.insurance_policy:'--' }</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                               <div className='flexdetails'>
                                    <div className='flexdleft'>Insurance Policy Number</div>
                                    <div className='flexdright'>{userDetails?.insurance_policy_number? userDetails?.insurance_policy_number : '--'}</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                <div className='flexdetails'>
                                    <div className='flexdleft'>Insurance Expiry</div>
                                    <div className='flexdright'>{userDetails?.insurance_expiry ? userDetails?.insurance_expiry : '--'}</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                 <div className='flexdetails'>
                                    <div className='flexdleft'>Parking Agreement</div>
                                    <div className='flexdright'>{userDetails?.parking_agreement ? userDetails?.parking_agreement :'--'}</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                <div className='flexdetails'>
                                    <div className='flexdleft'>Yard Rules</div>
                                    <div className='flexdright'>{userDetails?.yard_rules? userDetails?.yard_rules : "--"}</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                              <div className='flexdetails'>
                                    <div className='flexdleft'>Signature</div>
                                    <div className='flexdright'>{userDetails?.signature ? userDetails?.signature :'--'}</div>
                                </div>
                         </div>
                        
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                 <div className='flexdetails'>
                                    <div className='flexdleft'>PDK Pin</div>
                                    <div className='flexdright'>{userDetails?.pdk_pin ? userDetails?.pdk_pin:'--'}</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                <div className='flexdetails'>
                                    <div className='flexdleft'>Registered Date</div>
                                    <div className='flexdright'>{userDetails?.registered_date ? 
                                    moment(userDetails?.registered_date).format("DD MMM YYYY") :"--"}</div>
                                </div>
                         </div>
                         <div class="col-sm-12 col-md-6 col-lg-4">
                                 <div className='flexdetails'>
                                    <div className='flexdleft'>Parking Status</div>
                                    <div className='flexdright'>{userDetails?.status ? userDetails?.status : "--"}</div>
                                </div>
                         </div>
                    
                        
                        </div>
                        </div>
               </div>
              
            </>
        );
      
}
export default UserDetails;