import StorageService from "./storage.service";
import axiosService from "./axios.Service";

const login = async (_formData) => {
  try {
    console.log(_formData);
    let _axiosData = {
      method: "post",
      url: "adminlogin",
      formData: _formData,
    };

    const response = await axiosService.hit(_axiosData);

    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
};

const forgotPassword = async (_formData) => {
  try {
    
    let _axiosData = {
      method: "post",
      url: "forgetpassword",
      formData: _formData,
    };

    const response = await axiosService.hit(_axiosData);

    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
};

const verifyToken = async (token) => {
  try {
    let _axiosData = {
      method: "get",
      url: "verifytoken/" + token,
    };

    const response = await axiosService.hit(_axiosData);

    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
};

const resetPassword = async (_formData) => {
  try {
    let _axiosData = {
      method: "post",
      url: "resetpassword",
      formData: _formData,
    };

    const response = await axiosService.hit(_axiosData);

    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
};

const logout = () => {
  StorageService.clear();
};

const AuthService = {
  login,
  forgotPassword,
  verifyToken,
  resetPassword,
  logout,
};

export default AuthService;
