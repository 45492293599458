import React from "react";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";
const Pagination = (props) => {
  const handlePagination = (current) => {
    props.pagination(current);
  };
  return (
    <>
      <div className="flex items-center justify-between border-t border-gray-200 py-3 ">
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between ">
          <div>
            {/* <p className="text-sm text-gray-700">
              Showing <span className="font-medium">1</span> to{" "}
              <span className="font-medium">10</span> of{" "}
              <span className="font-medium">97</span> results
            </p> */}
          </div>
          <div>
            <nav
              className="isolate inline-flex -space-x-px rounded-md  bg-white"
              aria-label="Pagination"
            >
              <a
                onClick={() => handlePagination(props.current - 1)}
                className={ props.current === 1 ? "pointer-events-none relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0" : " relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0"  }
              >
                
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Prev</span>
              </a>
              {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
              {/* <a
               
                aria-current="page"
                className="relative z-10 inline-flex items-center bg-brandBlue px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                1
              </a>
              <a
               
                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0"
              >
                2
              </a> */}
              {props.total < 7 ? (
                <>
                  {Array.apply(0, Array(props.total)).map((arr, i) => (
                    <a
                      onClick={() => handlePagination(i + 1)}
                      className={ props.current === i + 1  ? 'activeclass' : 'paginationlink'}
                    >
                      {i + 1}
                    </a>
                  ))}
                </>
              ) : props.current % 5 >= 0 &&
                props.current > 4 &&
                props.current + 2 < props.total ? (
                <>
                  <a className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer">
                    1
                  </a>
                  <a className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer">
                    ...
                  </a>

                  <a
                    onClick={() => handlePagination(props.current - 1)}
                    className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer"
                  >
                    {props.current - 1}
                  </a>

                  <a
                    onClick={() => handlePagination(props.current)}
                    className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer"
                  >
                    {props.current}
                  </a>

                  <a
                    onClick={() => handlePagination(props.total + 1)}
                    className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer"
                  >
                    {props.current + 1}
                  </a>
                  <a className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer">
                    ...
                  </a>

                  <a
                    onClick={() => handlePagination(props.total)}
                    className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer"
                  >
                    {props.total}
                  </a>
                </>
              ) : props.current % 5 >= 0 &&
                props.current > 4 &&
                props.current + 2 >= props.total ? (
                <>
                  <a
                    onClick={() => handlePagination(1)}
                    className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer"
                  >
                    1
                  </a>

                  <a className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer">
                    ...
                  </a>

                  <a
                    onClick={() => handlePagination(props.total - 3)}
                    className={ props.current === props.total - 3  ? ' pointer-events-none relative inline-flex  items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 dark:text-black dark:bg-[#97c05b] text-black bg-[#97c05b]' : 'relative inline-flex  items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer'}
                  >
                    {props.total - 3}
                  </a>

                  <a
                    onClick={() => handlePagination(props.total - 2)}
                    className={ props.current === props.total - 2  ? ' pointer-events-none relative inline-flex  items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 dark:text-black text-black dark:bg-[#97c05b] bg-[#97c05b]' : 'relative inline-flex  items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer'}
                  >
                    {props.total - 2}
                  </a>

                  <a
                    onClick={() => handlePagination(props.total - 1)}
                    className={ props.current === props.total - 1 ? 'relative inline-flex pointer-events-none items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 dark:text-black dark:bg-[#97c05b] bg-[#97c05b] text-black' : 'relative inline-flex  items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer '}
                  >
                    {props.total - 1}
                  </a>
                  <a
                    onClick={() => handlePagination(props.total)}
                    className={props.current === props.total ? 'relative inline-flex  pointer-events-none items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 dark:hover:bg-gray-500 focus:outline-offset-0 dark:text-black text-black dark:bg-[#97c05b] bg-[#97c05b]' : 'relative inline-flex  items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20  focus:outline-offset-0 cursor-pointer  '}
                  >
                    {props.total}
                  </a>
                </>
              ) : (
                <>
                  {Array.apply(0, Array(5)).map((arr, i) => (
                    <a
                      onClick={() => handlePagination(i + 1)}
                      className={props.current === i + 1 ? 'relative inline-flex pointer-events-none items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 dark:text-black text-black dark:bg-[#97c05b] bg-[#97c05b]' : 'relative inline-flex  items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 cursor-pointer focus:outline-offset-0  '}
                      >
                      {i + 1}
                    </a>
                  ))}
                  <a className="relative inline-flex  text-black items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer">
                    ...
                  </a>

                  <a
                    onClick={() => handlePagination(props.total)}
                    className="relative inline-flex  text-black  items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer"
                  >
                    {props.total}
                  </a>
                </>
              )}
              <a
                onClick={() => handlePagination(props.current + 1)}
                className={ props.current === props.total ? "pointer-events-none relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0" : " relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-500 focus:z-20 focus:outline-offset-0 cursor-pointer"  }
              >
              <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pagination;
