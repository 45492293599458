import axiosService from "./axios.Service";


const getUserList = async (_perPage, _page, commonSearch, fromDate, toDate, status) => {
  try {
   
    let _axiosData = {
      method: "get",
      url: "registrationlist",
      isAuthorized: true,
      params: {
        perPage: _perPage || 10,
        page: _page || 1,
        commonSearch: commonSearch,
        fromDate,
        toDate,
        status: status,
      },
    };
    const response = await axiosService.hit(_axiosData);
    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
};

const exportUserList = async (commonSearch, fromDate, toDate, status) => {
  try {
   
    let _axiosData = {
      method: "get",
      url: "registrationlistexport",
      isAuthorized: true,
      params: {
        commonSearch: commonSearch,
        fromDate,
        toDate,
        status: status,
      },
    };
    const response = await axiosService.hit(_axiosData);
    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
};

const getUserDetails = async (_userId) => {
  try {
    let _axiosData = {
      method: "get",
      url: "registrationdetails/" + _userId,
      isAuthorized: true,
    };
    const response = await axiosService.hit(_axiosData);
    return response.data;
  } catch (error) {
    throw Error(error.message);
  }
};

const UserService = {
  getUserList,
  exportUserList,
  getUserDetails
};

export default UserService;
