import React, { Component, Fragment } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';


class TruckInfoForm extends Component {

  render() {
    const {
      registerIndex,
      changeRegisterIndex,
      submitTruckInfo,
      onFieldChange,
      totalSteps,
      truck
    } = this.props;
    const TruckInfoSchema = !truck ? Yup.object().shape({
      truck_color: Yup.string()
        .required('Please enter this field'),
      truck_register_number: Yup.string()
        .required('Please enter this field'),
      truck_licence_expiry: Yup.date()
        .required('Please enter this field'),
      truck_register_state: Yup.string()
        .required('Please enter this field')
    }) : Yup.object().shape({
      truck_color: Yup.string()
        .required('Please enter this field')
    });
    const resetTouched = (touched, values) => {
      Object.keys(key => {
        if (key !== 'truck') {
          touched[key] = false;
        }
      });
      values['truck_register_number'] = '';
      values['truck_licence_expiry'] = '';
      values['truck_register_state'] = '';
    }
    return (
      <Fragment>
        <Row>
          <Col sm="12">
            <h3 className="mb-4"><span className='step-count'>{`Step ${registerIndex}/${totalSteps} - `}</span> Truck Information:</h3>
            <div className="form">
              <Formik
                initialValues={{
                  truck_color: '',
                  truck_register_number: '',
                  truck_licence_expiry: '',
                  truck_register_state:''
                }}
                validationSchema={TruckInfoSchema}
                onSubmit={values => {
                  submitTruckInfo(values);
                  window.scrollTo(0, 0);
                  changeRegisterIndex(registerIndex + 1)
                }}
              >
                {({ handleChange, handleBlur, errors, touched, values }) => (
                  <Form noValidate>
                    <div className="form-group row mb-5" id="truck_color">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Truck Color:
                      </label>
                      <div className="col-sm-7">
                        <Field name="truck_color"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.truck_color}
                        />
                      </div>
                      {errors.truck_color && touched.truck_color ? (
                        <div className='validation-error col-12 text-right'>{errors.truck_color}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="truck">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Upload Truck License:
                      </label>
                      <div className="col-sm-7">
                        <div className="row">
                          <div className="col-10">
                            <input name="truck"
                              type="file"
                              className="form-control"
                              onChange={e => {
                                handleChange(e)
                                onFieldChange(e.target.files[0], 'truck');
                                resetTouched(touched, values);
                              }}
                              disabled={values.truck_register_number || values.truck_licence_expiry || values.truck_register_state}
                              onBlur={handleBlur}
                              accept="image/*"
                              value={values.truck}
                            />
                          </div>
                          <div className="col-2">
                            <button disabled={!truck} type="button" className="btn btn-light" onClick={_ => {
                              values.truck = '';
                              onFieldChange(null, 'truck');
                            }}>Clear</button>
                          </div>
                        </div>
                        <small className="d-block mt-2">docx, png, jpg and pdf 2MB max</small>
                      </div>
                      {errors.truck && touched.truck ? (
                        <div className='validation-error col-12 text-right'>{errors.truck}</div>
                      ) : null}
                    </div>

                    <div className="or-separator">(OR)</div>
                    <div className="row mb-3">
                      <label className="form-label col-sm-12">
                        Enter the following fields with required information.
                      </label>
                    </div>

                    <div className="form-group row" id="truck_register_number">
                      <label className="col-form-label mandatory form-label col-sm-5">
                       Registration Number:
                      </label>
                      <div className="col-sm-7">
                        <Field name="truck_register_number"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          disabled={truck}
                          onBlur={handleBlur}
                          value={values.truck_register_number}
                        />
                      </div>
                      {errors.truck_register_number && touched.truck_register_number ? (
                        <div className='validation-error col-12 text-right'>{errors.truck_register_number}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="truck_licence_expiry">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Expiry Date:
                      </label>
                      <div className="col-sm-7">
                        <Field name="truck_licence_expiry"
                          type="date"
                          className="form-control"
                          disabled={truck}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.truck_licence_expiry}
                        />
                      </div>
                      {errors.truck_licence_expiry && touched.truck_licence_expiry ? (
                        <div className='validation-error col-12 text-right'>{errors.truck_licence_expiry}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="truck_register_state">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Registered State:
                      </label>
                      <div className="col-sm-7">
                        <Field name="truck_register_state"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          disabled={truck}
                          onBlur={handleBlur}
                          value={values.truck_register_state}
                        />
                      </div>
                      {errors.truck_register_state && touched.truck_register_state ? (
                        <div className='validation-error col-12 text-right'>{errors.truck_register_state}</div>
                      ) : null}
                    </div>
                    
                    <div className="actions mt-3 text-center">
                      <Button onClick={_ => {
                        window.scrollTo(0, 0);
                        changeRegisterIndex(registerIndex - 1)
                      }} type="button" className="form-btn float-left"><BsChevronLeft /> Back</Button>
                      <Button type="submit" className="form-btn float-right">Next <BsChevronRight /></Button>
                    </div>
                  </Form>)}
              </Formik>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default TruckInfoForm;