import React, { Component, Fragment } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

class DriverInfoForm extends Component {
  render() {
    const {
      registerIndex,
      changeRegisterIndex,
      submitDriverInfo,
      onFieldChange,
      totalSteps,
      driver
    } = this.props;
    const DriverInfoSchema = !driver ? Yup.object().shape({
      driver_licence_number: Yup.string()
        .required('Please enter this field'),
      driver_licence_expiry: Yup.date()
        .required('Please enter this field'),
      driver_address: Yup.string()
        .required('Please enter this field'),
      driver_name: Yup.string()
        .required('Please enter this field'),
    }) : Yup.object().shape({});

    const resetTouched = (touched, values) => {
      Object.keys(key => {
        if (key !== 'driver') {
          touched[key] = false;
        }
      });
      values['driver_address'] = '';
      values['driver_licence_expiry'] = '';
      values['driver_licence_number'] = '';
      values['driver_name'] = '';
    }
    return (
      <Fragment>
        <Row>
          <Col sm="12">
            <h3 className="mb-4"><span className='step-count'>{`Step ${registerIndex}/${totalSteps} - `}</span> Driver Information:</h3>
            <div className="form">
              <Formik
                initialValues={{
                  driver_licence_number: '',
                  driver_licence_expiry: '',
                  driver_address: '',
                  driver_name: '',
                }}
                validationSchema={DriverInfoSchema}
                onSubmit={values => {
                  submitDriverInfo(values);
                  window.scrollTo(0, 0);
                  changeRegisterIndex(registerIndex + 1)
                }}
              >
                {({ handleChange, handleBlur, errors, touched, values }) => (
                  <Form noValidate>
                    <div className="form-group row" id="driver">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Upload Driving License:
                      </label>
                      <div className="col-sm-7">
                        <div className="row">
                          <div className="col-10">
                            <input name="driver"
                              type="file"
                              className="form-control"
                              onChange={e => {
                                handleChange(e)
                                onFieldChange(e.target.files[0], 'driver');
                                resetTouched(touched, values);
                              }}
                              onBlur={handleBlur}
                              value={values.driver}
                              accept="image/*"
                              disabled={values.driver_address || values.driver_licence_expiry || values.driver_licence_number || values.driver_name}
                            />
                          </div>
                          <div className="col-2">
                            <button disabled={!driver} type="button" className="btn btn-light" onClick={_ => {
                              values['driver'] = '';
                              onFieldChange(null, 'driver');
                            }}>Clear</button>
                          </div>
                        </div>
                        <small className="d-block mt-2">docx, png, jpg and pdf 2MB max</small>
                      </div>
                      {errors.driver && touched.driver ? (
                        <div className='validation-error col-12 text-right'>{errors.driver}</div>
                      ) : null}
                    </div>
                    <div className="or-separator">(OR)</div>
                    <div className="row mb-3">
                      <label className="form-label col-sm-12">
                        Enter the following fields with required information.
                      </label>
                    </div>
                    <div className="form-group row" id="driver_name">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Name:
                      </label>
                      <div className="col-sm-7">
                        <Field name="driver_name"
                          type="text"
                          disabled={driver}
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.driver_name}
                        />
                      </div>
                      {errors.driver_name && touched.driver_name ? (
                        <div className='validation-error col-12 text-right'>{errors.driver_name}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="driver_licence_number">
                      <label className="col-form-label mandatory form-label col-sm-5">
                       License Number:
                      </label>
                      <div className="col-sm-7">
                        <Field name="driver_licence_number"
                          type="text"
                          className="form-control"
                          disabled={driver}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.driver_licence_number}
                        />
                      </div>
                      {errors.driver_licence_number && touched.driver_licence_number ? (
                        <div className='validation-error col-12 text-right'>{errors.driver_licence_number}</div>
                      ) : null}
                    </div>
                    <div className="form-group row" id="driver_licence_expiry">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Expiry Date:
                      </label>
                      <div className="col-sm-7">
                          <Field name="driver_licence_expiry"
                            type="date"
                          className={`form-control ${driver ? 'disabled':''}`}
                          disabled={driver}
                          onChange={handleChange}
                          onFocus={()=>this.type = 'date'}
                            onBlur={handleBlur}
                            value={values.driver_licence_expiry}
                          />
                      </div>
                      {errors.driver_licence_expiry && touched.driver_licence_expiry ? (
                        <div className='validation-error col-12 text-right'>{errors.driver_licence_expiry}</div>
                      ) : null}
                    </div>
                    <div className="form-group row" id="driver_address">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Address:
                      </label>
                      <div className="col-sm-7">
                        <Field name="driver_address"
                          type="text"
                          className="form-control"
                          disabled={driver}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.driver_address}
                        />
                      </div>
                      {errors.driver_address && touched.driver_address ? (
                        <div className='validation-error col-12 text-right'>{errors.driver_address}</div>
                      ) : null}
                    </div>
                    <div className="actions mt-3 text-center">
                      <Button onClick={_ => {
                        window.scrollTo(0, 0);
                        changeRegisterIndex(registerIndex - 1)
                      }} type="button" className="form-btn float-left"><BsChevronLeft /> Back</Button>
                      <Button type="submit" className="form-btn float-right">Next <BsChevronRight /></Button>
                    </div>
                  </Form>)}
              </Formik>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default DriverInfoForm;