import axios from "axios";
import ConfigService from "./config.service";
import CONFIG from "../service-config";
const apiBaseUrl = CONFIG.BASE_URL;

/*Request Sample

_axiosData = {
  method : 'get', // get || post || put
  url: 'admin/rarities', // Only endpoint
  isAuthorized : true, // true or false
  isMultipart : true, // true or false
  params : {
    perPage: _perPage || 10,
    page: _page || 1,
    searchData: _searchData,
    isAll: _isAll
  },
  formData: // Only in case of post and put request
}
*/
const hit = async (_request) => {
  try {
    let response = {};
    let _url = apiBaseUrl + _request.url;
    let _config = {
      headers: {
        "content-type": _request.isMultipart
          ? "multipart/form-data"
          : "application/json",
        Authorization: _request.isAuthorized
          ? ConfigService.authorizationToken()
          : null,
      },
    };

    if (_request.params) {
      _config["params"] = _request.params;
    }

    if (_request.method === "get") {
   
      response = await axios.get(_url, _config);
    } else {
    
      response = await axios[_request.method](_url, _request.formData, _config);
    }

    return response;
  } catch (error) {
    if (error.response.status === 500) {
      throw Error(error.response.data.message);
    }
    throw Error(error.message);
  }
};

const axiosService = {
  hit,
};

export default axiosService;
