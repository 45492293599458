 import React, { Component, useEffect, useState } from 'react';
import Logo from '../../assets/logo.jpeg';
import { Link} from 'react-router-dom';
import AuthService from '../../service/auth.service';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Spinner } from "../../service/notiflix.service";
import { useForm } from "react-hook-form";

const schema = Yup.object({
  email: Yup.string()
     .required("Email address is required")
     .email("Please enter a valid email address")
  }).required();


function ForgotPassword() {
 
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });


  const onSubmit = (data) => {
    onForgotPassword(data);
  };
               
  const onForgotPassword = async (_formInput) => {

    Spinner.show();
    try {

      let _formData = new FormData();

      _formData.append("email", _formInput.email);
    
      const response = await AuthService.forgotPassword(_formData);
      Spinner.hide();

      if (response.status === "failure") {
        Alert.error(response.message);
        return false;
      }

      Alert.success(response.message);
      reset();
    } catch (error) {
      console.log(error);
      Spinner.hide();
      Alert.error(error.message);
    }
  };               

  return (
          <>
             
              <div className='flexloginauth'>
                  <div className='loginclassregister'>
                        <div className='logologin'><img className="logo" alt="logo" src={Logo} /></div>
                      <h2>Forgot Password</h2>
                      <form onSubmit={handleSubmit(onSubmit)}>
                      <div className='inputflex mb-40'>
                        <label>Email Address</label>
                        <input 
                        {...register("email", { required: true })}
                        className="mt-1"
                        type="email"
                        placeholder="john@doe.com"
                        autoComplete="off"
                      
                        />
                        <div className="errorred">
                        {errors.email?.message}
                        </div>
                   
                     </div>
                     <div>
                          <button type="submit" className='buttonclass wfull'>Recover Password</button>
                      </div> 
                    
                      <p className="mt-4 flex items-end justify-end">
                        <Link className="text-sm font-medium text-gray-700 dark:text-gray-200 underline"
                          to="/admin_login">
                          Back to Login
                        </Link>
                      </p>  
                      </form>
                     
                    </div>
               </div> 
          </>
        );
}


export default ForgotPassword;


