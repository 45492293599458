import React, { Component, Fragment } from 'react';
import { Col, Row, Button, Spinner } from 'react-bootstrap';
import { format, add} from 'date-fns';
// import { BiHappy, BiSad } from "react-icons/bi";
import { BsChevronRight } from "react-icons/bs";
import { VscCalendar } from "react-icons/vsc";
import DatePicker from 'react-datepicker';
import moment from 'moment';

class DatePickerForm extends Component {
  constructor(props) {
    super(props);
    this.myInp1 = React.createRef();
    this.myInp2 = React.createRef();
    this.hideEndDate = true;
    this.oldStartDate = '';
    this.oldEndDate = '';
    this.errorMsg = false;
    this.state = {
      vehicleIdentification: "",
      vin: "",
      vehicleType:"trailerOnly",
      parkingDayType:"monthly",
      noOfDays: 1,
      error: false
    };
    
  }

  handleVehicleTypeChange = (value) => {
    const { onFieldChange } = this.props;
    const price = value === 'tractorTrailer' ? 250 : 195;
    this.setState({ vehicleType: value });
    onFieldChange(value, 'vehicleType');
    onFieldChange(price, 'vehiclePrice');
  };

  
  componentDidMount() {
    this.setDate(new Date());
  }
  
  setDate = (date, months = "monthly",type="") => {

    const parkingDayType = months; 

    this.setState({ parkingDayType });

    let showEndTime = moment(date).format('LT'); 
   
    const newTime = date.toLocaleTimeString('it-IT');
    // newDate = date.toLocaleDateString('en-US');
    let startTime = '';
    let endTime = "";
  
      if(months === "monthly")
      {
        const { onFieldChange, getAvailableDates, changeTotalSteps } = this.props;
        const startDate = date;
        startTime = newTime;
        changeTotalSteps(7)
        onFieldChange(startDate, 'startDate');
        onFieldChange(startTime, 'startTime');

        let endDate = add(startDate, { days: 30})
        endTime = newTime;
        onFieldChange(endDate, 'endDate');
        onFieldChange(endTime, 'endTime');
        onFieldChange(parkingDayType, 'parkingDayType');
        onFieldChange(showEndTime, 'showEndTime');
        getAvailableDates({
          startDate: startDate,
          endDate: endDate
         
        });
        this.hideEndDate = true;

      }else if(months === "daily")
      {
        const { onFieldChange, getAvailableDates, changeTotalSteps } = this.props;
        changeTotalSteps(3)
        const startDate = date;
        startTime = newTime;
        onFieldChange(startDate, 'startDate');
        onFieldChange(startTime, 'startTime');

        let endDate = add(startDate, { days: 1})
        endTime = newTime;
        onFieldChange(endDate, 'endDate');
        onFieldChange(endTime, 'endTime');
        onFieldChange(parkingDayType, 'parkingDayType');
        onFieldChange(showEndTime, 'showEndTime');
        getAvailableDates({
          startDate: startDate,
          endDate: endDate
         
        });
        this.hideEndDate = true;
        onFieldChange("daily", 'vehicleType');
        onFieldChange(25, 'vehiclePrice');
      }

    
  }
  createSelectItems() {
    let items = [];
    for (let i = 1; i <= 24; i++) {
      items.push(<option key={i} value={i}>{`${i} Month${i === 1 ? '' : 's'}`}</option>);
    }
    return items;
  }

  handleVehicleChange = (value) => {
    const { onFieldChange} = this.props;
    this.setState({vehicleIdentification: value});
    onFieldChange(value, 'vehicleIdentification');

    if(this.state.vehicleIdentification && this.state.vin)
    {
      this.setState({error: false});
    }
  }

  handleNoOfDayChange = (value) => {
    if(value > 0){
      const { onFieldChange, startDate} = this.props;
      this.setState({noOfDays: value});
      onFieldChange(value, 'noOfDays');
      let endDate = add(startDate, { days: value})
      onFieldChange(endDate, 'endDate');
    }
  }

  handleVinChange = (value) => {
    const { onFieldChange} = this.props;
    this.setState({vin: value});
    onFieldChange(value, 'vin');
    if(this.state.vehicleIdentification && this.state.vin)
    {
      this.setState({error: false});
    }
  }
  
  render() {

    const {showEndTime,parkingDayType,startDate, endDate, registerIndex, slotAvailable, changeRegisterIndex, loading, totalSteps, slotMsg } = this.props;

    return (
      <Fragment>
        <Row>
          <Col sm="12">
            <h3 className="mb-4"><span className='step-count'>{`Step ${registerIndex}/${totalSteps} - `}</span> Choose your dates to park your truck for Monthly Parking:</h3>
            <div className="form">

       
            <div className="form-group mb-2 row" id="parkingDayType">
              <label className="col-form-label form-label mandatory col-sm-5">
              Frequency:
              </label>
              <div className="col-sm-7">
                <select className='form-control' onChange={e => this.setDate(startDate, e.target.value)}>
                  {/* {this.createSelectItems()} */}
                  {/* <option value="one_day">One Day</option> */}
                  <option value="monthly">Monthly</option>
                  <option value="daily">Daily</option>
                </select>
              </div>
            </div>
        
            

          <div className="form-group mb-1 row" id="startDate">
            <label className="col-form-label form-label mandatory col-sm-5">
              Start Date:
            </label>
            <div className="col-sm-7">
              <DatePicker
                className='form-control'
                ref={(ip) => this.myInp1 = ip}
                selected={startDate}
                minDate={new Date()}
                onChange={(date) => this.setDate(date,parkingDayType,"start")}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MM/dd/yyyy h:mm aa"
                id="sDate"
                value={startDate}
                  
              />
              <VscCalendar onClick={() => {
                try {
                  this.myInp1.setFocus()
                }catch(e) { console.log(e)}
              }} className="form-icon"/>

            </div>
         
          </div>

          {
            this.state.parkingDayType === "daily" && (
          <div className="form-group mb-2 row" id="parkingDayType">
            <label className="col-form-label form-label mandatory col-sm-5">
              Number of days:
            </label>
            <div className="col-sm-7">
              <select
                className="form-control"
                onChange={(e) => this.handleNoOfDayChange(e.target.value)}
              >
                {/* <option value="">Select a day</option> */}
                {[...Array(29).keys()].map((day) => (
                  <option key={day + 1} value={day + 1}>
                    {day + 1}
                  </option>
                ))}
              </select>
            </div>
          </div>
            )
          }

          <div className="form-group row" id="vehicle_identification">
            <label className="col-form-label mandatory form-label col-sm-5">
            Vehicle Identification:
            </label>
            <div className="col-sm-7">
              <input name="vehicle_identification"
                type="text"
                required
                className="form-control"
                onChange={(e) => this.handleVehicleChange(e.target.value)}
              />
            </div>
            {!this.state.vehicleIdentification && this.state.error &&
            <div className='validation-error col-12 text-right'>{"Vehicle Identification is required"}</div>}
          </div>

          <div className="form-group row" id="vehicle_identification">
            <label className="col-form-label mandatory form-label col-sm-5">
           VIN
            </label>
            <div className="col-sm-7">
              <input name="vin"
                type="text"
                required
                className="form-control"
                onChange={(e) => this.handleVinChange(e.target.value)}
              />
            </div>
            {!this.state.vin && this.state.error &&
            <div className='validation-error col-12 text-right'>{"VIN is required"}</div>}
          </div>
          
          {
            this.state.parkingDayType === "monthly" && (
          <>
          <div className="form-group row">
          <label className="col-form-label col-sm-5">Vehicle Type Pricing</label>
          <div className="col-sm-7">
            <label>
              <input
                type="radio"
                name="vehicleType"
                value="tractorTrailer"
                checked={this.state.vehicleType === 'tractorTrailer'}
                onChange={() => this.handleVehicleTypeChange('tractorTrailer')}
              />
              {" "}Tractor Trailer ( $250/Month )
            </label>
            <br />
            <label>
              <input
                type="radio"
                name="vehicleType"
                value="trailerOnly"
                checked={this.state.vehicleType === 'trailerOnly'}
                onChange={() => this.handleVehicleTypeChange('trailerOnly')}
              />
             {" "} Trailer Only ( $195/Month )
            </label>
          </div>
        </div>

        <div className="pricing-section">
          <h3>Pricing</h3>
          {this.state.vehicleType === 'tractorTrailer' ? (
            <p>$250 per month for Tractor Trailer</p>
          ) : (
            <p>$195 per month for Trailer Only</p>
          )}
        </div>
          </>
          )
        }
    

        {this.hideEndDate == true && (
           <div className="form-group row mt-3 " id="endDate" disabled>
            
           <label className="col-form-label form-label col-sm-12 flexdiv">
            <div>
            End Date: <span className="orange-txt header-text-h3">{format(endDate, 'MM/dd/yyyy')}</span>
            </div>
            <div>
            End Time: <span className="orange-txt header-text-h3">{showEndTime}</span>
            </div>
            
             
           </label>
           </div>
        )}  
          
              <Fragment>
                <h5>{slotMsg}</h5>
              </Fragment>
              <div className="actions mt-3 text-center">
                {registerIndex < 5 && <Button disabled={!slotAvailable || this.errorMsg == true} type="submit" onClick={_ => {

                  if(!this.state.vehicleIdentification || !this.state.vin)
                  {
                    this.setState({error: true});
                    return
                  }
                  window.scrollTo(0, 0);

                  changeRegisterIndex(registerIndex + 1)
                }} className="form-btn float-right">Next <BsChevronRight /></Button>}
                {loading && <Spinner animation="border" />}
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default DatePickerForm;