import React, { Component } from 'react';
import Banner from '../../assets/banner.jpg';
import './styles.scss';
import DatePickerForm from './components/DatePickerForm';
import PersonalInfoForm from './components/PersonalInfoForm';
import CompanyInfoForm from './components/CompanyInfoForm';
import DriverInfoForm from './components/DriverInfoForm';
import TruckInfoForm from './components/TruckInfoForm';
import InsuranceInfoForm from './components/InsuranceInfoForm';
import LegalDisclaimerForm from './components/LegalDisclaimerForm';


class RegisterComponent extends Component {
  render() {
    const {
      registerIndex,
      parkingDayType
    } = this.props;

    return (
      <div className="register">
        <div className="banner-container" style={{ 'backgroundImage': `url(${Banner})` }}>
        </div>
        <div className="container">
          <div className="register-container">
            <div className="register-content">
              <h1>PARK MY TRUCK USA</h1>
              {/* <p>
                was founded on a few basic principles for the <strong>YOU</strong>, the driver, in mind. We want to make sure the customers experience is always above expectation. The old cliché of a truck parking lot with minimal or no security, bad lighting, and poor road surface conditions will not be the case here. <strong>YOU</strong> will always have well lit trucking spots that can be monitored by YOU 24 hours a day. Our tech app allows <strong>YOU</strong> to view in real time.
              </p> */}
               <p>
              Thank You For Choosing
               <strong> PARK MY TRUCK USA </strong>
               You're only a few clicks away from safe, secure, and convenient truck parking.
              Here, we've streamlined the process to ensure a hassle-free experience for our valued customers. Conveniently manage your payments, select your location, schedule your stops, and receive your designated parking spot.
              Whether you’re looking for a safe and secure place to park your rig overnight or a long-term storage solution, we’ve got you covered. With convenient locations, 24/7 security monitoring, secure fencing, remote gate access, and nearby amenities, we’re your one-stop-shop for all your truck parking needs. So why wait? Park your truck with us and rest easy knowing you are in good hands.
              
              
            </p>

              <p>
              Secure your spot now and enjoy the peace of mind that comes with choosing <strong>Park My Truck USA!</strong>
              </p>
            </div>
            <div className="register-form">
              <h2 className="mb-3">MONTHLY PARKING AGREEMENT</h2>
              <div className={registerIndex === 1 ? '' : 'd-none'}><DatePickerForm {...this.props} /></div>
              <div className={registerIndex === 2 ? '' : 'd-none'}><PersonalInfoForm {...this.props} /></div>
              {
              parkingDayType === 'monthly' ? (
                <>
                <div className={registerIndex === 3 ? '' : 'd-none'}><CompanyInfoForm {...this.props} /></div>
                <div className={registerIndex === 4 ? '' : 'd-none'}><DriverInfoForm {...this.props} /></div>
                <div className={registerIndex === 5 ? '' : 'd-none'}><TruckInfoForm {...this.props} /></div>
                <div className={registerIndex === 6 ? '' : 'd-none'}><InsuranceInfoForm {...this.props} /></div>
                <div className={registerIndex === 7 ? '' : 'd-none'}><LegalDisclaimerForm {...this.props} /></div>
                </>
              )
              : <div className={registerIndex === 3 ? '' : 'd-none'}><LegalDisclaimerForm {...this.props} /></div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterComponent;