import React, { Component, Fragment } from 'react';
import { Form, Col, Row, Button, Spinner, Modal } from 'react-bootstrap';
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

class LegalDisclaimerForm extends Component {
  state = {
    show: false,
    tenantSignatureCheck: false
  }
  handleClose = (type) => {
    const { onFieldChange, first_name, last_name } = this.props;
    if (type === 'close') {
      this.setState({ show: false, tenantSignatureCheck: false });
      onFieldChange('', 'signature')
    } else {
      this.setState({ show: false, tenantSignatureCheck: true });
      onFieldChange(first_name + ' ' + last_name, 'signature')
    }
  }
  displaySignaturePopup =(checked) => {
    this.setState({ show: checked, tenantSignatureCheck: false});
  }
  render() {
    const {
      onFieldChange,
      first_name,
      last_name,
      agreeTerms,
      registerIndex,
      changeRegisterIndex,
      loading,
      registerTruck,
      registrationSuccess,
      message,
      totalSteps
    } = this.props;
    const { show, tenantSignatureCheck } = this.state;
    return (
      <Fragment>
        <Row className="form">
          <Col sm="12">
            <h3 className="mb-4"><span className='step-count'>{`Step ${registerIndex}/${totalSteps} - `}</span> Legal Disclaimers:</h3>
            <div className="terms-container">
              <h3 className="text-center"><strong>PARKING AGREEMENT</strong></h3>
              <p>
                <strong>A. Location</strong><br/>
                Landlord hereby grants to Tenant a nontransferable right and revocable permission for the temporary use of and right to occupy the assigned premises (the “Parking Area”).  Tenant accepts the Parking Area in “As Is” condition. Landlord makes no representations or warranties as to the condition of the Parking Area or its suitability for Tenant’s intended use. Landlord may reassign Tenant to alternate Location with a 30 day written notice.
              </p>
              <p>
                <strong>B. Parking Area</strong><br />
                Tenant will park in the assigned area only. Any vehicles parked outside of the assigned area will be towed at the Tenant’s expense to an on-site or off-site location.
              </p>
              <p>
                <strong>C. Use</strong><br />
                Tenant will use the Parking Area for the sole purpose of parking.  Truck maintenance, repair and/or truck washing is to be completed by a Landlord pre-approved mechanic/vendor only. At all times, Tenant will observe and comply with all laws, ordinances, rules, permits, licenses, regulations and code requirements, required or imposed by all governmental agencies, as well as those of Landlord. Tenant is financially responsible for any damage to structures, fence, and signage and other vehicles on Location caused by Tenant and/or Tenant employees and associates.
              </p>
              <p>
                <strong>D. Rent Payment</strong><br/>
                Payment of first month and last month rent is due within one business day of signing this Agreement and prior to yard access.  All subsequent payments are due on the first of each month. Payment is to be paid by Tenant to Landlord by credit card, company check, certified check or cash. Credit card information may be securely stored on file.  A late fee of $25.00 per day will be assessed for any payment received after the 5th of the month.  Non-payment may result in limited to no access to ParkMyTruckUSA Locations.
              </p>
              <p>
                <strong>E. Term</strong> <br/>
                This agreement is month-to-month and will automatically renew on the first of each month until termination. A 30-day written notice is required to terminate this Agreement. The Agreement may be terminated early (less than 30 days) in writing by either party if any terms of the agreement are violated.  Full or partial refunds for early termination are prohibited.
              </p>
              <p>
                <strong>F. Risk</strong><br/>
                Landlord is not responsible for lost or stolen valuables of any kind, computer malfunctions or damage, Tenant accidents or injuries, personal emergencies or “acts of God” that may occur at Locations.
              </p>
              <h3 className="text-center"><strong>PARKING AGREEMENT</strong></h3>
              <p>
                I will provide written notice to ParkMyTruckUSA of any changes to my personal or company information listed above.
              </p>
              <p>
                I understand these rules apply to me and all persons and property associated with me/my company who enter the ParkMyTruckUSA yards. I am responsible for informing and enforcing these rules with all persons associated with me/my company who enter ParkMyTruckUSA yards.
              </p>
              <p>
                I will park in the space and areas assigned to me by ParkMyTruckUSA.
              </p>
              <p>
                I understand that any vehicles and equipment parked in areas assigned to other customers will be towed. I understand that I am responsible for all towing fees. Vehicles and equipment may be towed to a place on the yard, to another ParkMyTruckUSA location or to a third party impound yard.
              </p>
              <p>
                I will adhere to any update in the Yard Rules as communicated in writing to me by ParkMyTruckUSA.
              </p>
              <p>
                I understand non-compliance with these rules and the terms of this Agreement may result in limited to no access to ParkMyTruckUSA yards at any time.
              </p>
            </div>
            <br />
            <Form.Check
              type={'checkbox'}
              id={'agree'}
              checked={agreeTerms}
              label={`Yes I agree`}
              onChange={e => onFieldChange(e.target.checked, 'agreeTerms')}
            />
            <br />
            <Form.Group as={Row} className="mb-4" id="signature">
              <Form.Label column sm="6" className="tenant-signature">
                <Form.Check
                  type={'checkbox'}
                  id={'tenantSignatureCheck'}
                  checked={tenantSignatureCheck}
                  onChange={e => this.displaySignaturePopup(e.target.checked)}
                /> TENANT Signature:
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="text"
                  readOnly={true}
                  onChange={e => onFieldChange(e.target.value, 'signature')}
                  value={tenantSignatureCheck ? first_name + ' ' + last_name : '' }
                />
                {tenantSignatureCheck ? <div className="mt-2" id="display-signature">
                  {first_name + ' ' + last_name}
                </div> : ''}
              </Col>
            </Form.Group>
            
            <Fragment>
              <h5 className="mt-2">{message}</h5>
            </Fragment>
            <div className="actions mt-3 text-center">
              <Button onClick={_ => {
                window.scrollTo(0, 0);
                changeRegisterIndex(registerIndex - 1)
              }} type="button" className="form-btn float-left"><BsChevronLeft /> Back</Button>
              <Button type="button"
                className="form-btn float-right"
                onClick={_ => registerTruck()}
                disabled={!(agreeTerms && tenantSignatureCheck) || registrationSuccess === true}>Pay <BsChevronRight /></Button>
              {(loading || registrationSuccess) && <Spinner animation="border" />}
            </div>
          </Col>
        </Row>
        <Modal show={show} onHide={() => this.handleClose('close')} backdrop="static" centered>
          <Modal.Header closeButton>
            <Modal.Title><small>Confirmation</small></Modal.Title>
          </Modal.Header>
          <Modal.Body><h4>Aceept this as digital signature?</h4></Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={() => this.handleClose('close')}>
              No
            </Button>
            <Button variant="danger" onClick={() => this.handleClose('yes')}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}
 
export default LegalDisclaimerForm;