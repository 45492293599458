import React, { useState, useEffect } from "react";
import { Alert, Spinner } from "../../service/notiflix.service";
import { Link, useHistory } from "react-router-dom";
import UserService from "../../service/user.service";
import moment from "moment";
import Pagination from "../common/Pagination";
import userlistimg from "../../assets/recordnofound.png";
import * as XLSX from "xlsx";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const UserLists = () => {
  const [userList, setUsersList] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [, setTotalPages] = useState(1);
  const [commonSearch, setCommonSearch] = useState("");
  const [status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const history = useHistory();

  const [startDate, setStartDate] = useState(new Date());

  const [filterData, setFilterData] = useState({
    perPage: perPage,
    page: 1,
    commonSearch: "",
    fromDate: "",
    toDate: "",
    status: "",
  });

  const [isPagination, setPagination] = useState({
    totalPages: 10,
    currentPage: 1,
  });

  useEffect(() => {
    getUserList();
  }, [filterData]);

  const getUserList = async () => {
    try {
      Spinner.show();

      let response = await UserService.getUserList(
        filterData.perPage,
        filterData.page,
        filterData.commonSearch,
        filterData.fromDate,
        filterData.toDate,
        filterData.status
      );

      if (response.status === "failure") {
        Spinner.hide();
        Alert.error(response.message);
        return false;
      }
      setPagination({
        totalPages: response.data.pagination.totalPages,
        currentPage: response.data.pagination.page,
      });

      setUsersList([...response.data.records]);
      setTotalPages(response.data.pagination.totalPages);
      setPage(response.data.pagination.page);
      Spinner.hide();
    } catch (error) {
      Spinner.hide();
      Alert.error(error.message);
    }
  };

  const handleFilter = (e, field) => {
    if (field === "fromDate") {
      setFilterData({
        ...filterData,
        fromDate:e,
        perPage: perPage,
        page: page,
      });
    } else if (field === "toDate") {
      setFilterData({
        ...filterData,
        toDate:e,
        perPage: perPage,
        page: page,
      });
    } else if (field === "status") {
      setFilterData({
        ...filterData,
        status: e.target.value,
        perPage: perPage,
        page: page,
      });
    } else if (field === "other") {
      setFilterData({
        ...filterData,
        commonSearch: e.target.value,
        perPage: perPage,
        page: page,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleFilter();
    }
  };

  const handlePagination = (current) => {
    setPagination({ ...isPagination, currentPage: current });
    setFilterData({
      ...filterData,
      page: current,
    });
  };

  const resetFilter = () => {
    setFromDate("");
    setToDate("");
    setCommonSearch("");
    setStatus("");
    setFilterData({
      ...filterData,
      commonSearch: "",
      fromDate: "",
      toDate: "",
      status: "",
    });
  };

  const userRedirect = (user_id) => {
    history.push("user_details/" + user_id);
  };

  const [data, setData] = useState(null);

  const fetchDataAndExport = async () => {
    try {
      Spinner.show();

      let response = await UserService.exportUserList(
        filterData.commonSearch,
        filterData.fromDate,
        filterData.toDate,
        filterData.status
      );

      if (response.status === "failure") {
        Spinner.hide();
        Alert.error(response.message);
        return false;
      }
      setData(response.data.records);
      exportToExcel(response.data);
      Spinner.hide();
    } catch (error) {
      Spinner.hide();
      Alert.error(error.message);
    }
  };

  const exportToExcel = (dataToExport) => {
    const header = Object.keys(dataToExport.records[0]);
    const dataForExcel = dataToExport.records.map((record) =>
      header.map((field) => record[field])
    );

    const worksheet = XLSX.utils.aoa_to_sheet([header, ...dataForExcel]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    const filename = `userlisting_${timestamp}.xlsx`;
    XLSX.writeFile(workbook, filename);
  };

  return (
    <>
      <div className="container" style={{ padding: "20px" }}>
        <div className="">
          <div className="titlepage">User's Registration List</div>
          <div className="ilineflexusr flex flex-wrap mb-4">
            {/* Search Input */}
            <input
              placeholder="Search..."
              onKeyDown={handleKeyDown}
              type="text"
              name="commonSearch"
              id="commonSearch"
              onChange={(e) => {
                setCommonSearch(e.target.value);
                handleFilter(e, "other");
              }}
              value={commonSearch}
              className="custom-input"
            />

            {/* Status Filter */}
            <select
              name="status"
              id="status"
              onChange={(e) => {
                setStatus(e.target.value);
                handleFilter(e, "status");
              }}
              value={status}
              className="custom-input"
            >
              <option value="">Select Status</option>
              <option value="RESERVED">RESERVED</option>
              <option value="BOOKED">BOOKED</option>
            </select>

            {/* Date Inputs */}
            <DatePicker
              selected={fromDate}
              onChange={(date) => {
                setFromDate(date);
                handleFilter(date, 'fromDate');
              }}
              className="custom-input"
              dateFormat="dd-MM-yyyy"
              placeholderText="From Date"
            />

            {/* To Date Input */}
            <DatePicker
              selected={toDate}
              onChange={(date) => {
                setToDate(date);
                handleFilter(date, 'toDate');
              }}
              className="custom-input"
              dateFormat="dd-MM-yyyy"
              placeholderText="To Date"
            />

            {/* Clear Filters */}
            <button type="button" onClick={resetFilter} className="btn btn-secondary">
              Clear filters
            </button>

            {/* Export Button */}
            <button
              type="button"
              className="btn btn-primary"
              onClick={fetchDataAndExport}
            >
              Export to Excel
            </button>
          </div>
        </div>
        <div className="table-responsive-sm overflow-x-auto">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Truck Register Number</th>
                <th scope="col">Email Address</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Company Name</th>
                <th scope="col">Registration Date</th>
                <th scope="col">Parking Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {userList &&
                userList.length > 0 &&
                userList.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td scope="row">{index + 1}</td>
                      <td>{user?.first_name ? user?.first_name : "--"}</td>
                      <td>{user?.last_name ? user?.last_name : "--"}</td>
                      <td>
                        {user?.truck_register_number
                          ? user?.truck_register_number
                          : "--"}
                      </td>
                      <td>
                        {user?.email_address ? user?.email_address : "--"}
                      </td>
                      <td>
                        {user?.mobile_number ? user?.mobile_number : "--"}
                      </td>
                      <td>{user?.company_name ? user?.company_name : "--"}</td>
                      <td>
                        {user?.registered_date
                          ? moment(user?.registered_date).format("DD MMM YYYY")
                          : "--"}
                      </td>
                      <td>
                        {user?.status === "RESERVED" && (
                          <span className="py-[2px] px-3 rounded bg-gray-400 text-brandBlue text-sm whitespace-nowrap">
                            RESERVED
                          </span>
                        )}
                        {user?.status === "BOOKED" && (
                          <span className="py-[2px] px-3 rounded bg-green-400 text-brandBlue text-sm whitespace-nowrap">
                            BOOKED
                          </span>
                        )}
                      </td>

                      <td>
                        <button
                          onClick={() => userRedirect(user.id)}
                          className="actionbtn"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="paginationlists">
          {userList && userList.length > 0 && (
            <Pagination
              total={isPagination.totalPages}
              current={isPagination.currentPage}
              pagination={(crPage) => handlePagination(crPage)}
            />
          )}

          {userList && userList.length === 0 && (
            <div className="text-center mb-3 mt-12">
              <img className="" alt="logo" src={userlistimg} />
              <h5 className="nodatafount-text">No Data Found</h5>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default UserLists;
