import React, { Component, Fragment } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const zipCode = /\b\d{5}\b/;

const companyInfoSchema = Yup.object().shape({
  company_name: Yup.string()
    .required('Please enter this field'),
  company_url: Yup.string()
    .required('Please enter this field'),
  address_1: Yup.string()
    .required('Please enter this field'),
  city: Yup.string()
    .required('Please enter this field'),
  state: Yup.string()
    .required('Please enter this field'),
  zip: Yup.string()
    .matches(zipCode, { message: 'Please enter valid zip' })
    .typeError('Please enter only numbers')
    .required('Please enter this field')
});

class CompanyInfoForm extends Component {
  render() {
    const {
      registerIndex,
      changeRegisterIndex,
      submitCompanyInfo,
      totalSteps
    } = this.props;
    return (
      <Fragment>
        <Row>
          <Col sm="12">
            <h3 className="mb-4"><span className='step-count'>{`Step ${registerIndex}/${totalSteps} - `}</span> Company Information:</h3>
            <div className="form">
              <Formik
                initialValues={{
                  company_name: '',
                  company_url: '',
                  address_1: '',
                  address_2: '',
                  city: '',
                  state: '',
                  zip: ''
                }}
                validationSchema={companyInfoSchema}
                onSubmit={values => {
                  submitCompanyInfo(values);
                  window.scrollTo(0, 0);
                  changeRegisterIndex(registerIndex + 1)
                }}
              >
                {({ handleChange, handleBlur, errors, touched, values }) => (
                  <Form noValidate>
                    <div className="form-group row" id="company_name">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Company Name:
                      </label>
                      <div className="col-sm-7">
                        <Field name="company_name"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company_name}
                        />
                      </div>
                      {errors.company_name && touched.company_name ? (
                        <div className='validation-error col-12 text-right '>{errors.company_name}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="company_url">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Company URL:
                      </label>
                      <div className="col-sm-7">
                        <Field name="company_url"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.company_url}
                        />
                      </div>
                      {errors.company_url && touched.company_url ? (
                        <div className='validation-error col-12 text-right '>{errors.company_url}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="address_1">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Address 1:
                      </label>
                      <div className="col-sm-7">
                        <Field name="address_1"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address_1}
                        />
                      </div>
                      {errors.address_1 && touched.address_1 ? (
                        <div className='validation-error col-12 text-right '>{errors.address_1}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="address_2">
                      <label className="col-form-label form-label col-sm-5">
                        Address 2:
                      </label>
                      <div className="col-sm-7">
                        <Field name="address_2"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address_2}
                        />
                      </div>
                    </div>

                    <div className="form-group row" id="city">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        City:
                      </label>
                      <div className="col-sm-7">
                        <Field name="city"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.city}
                        />
                      </div>
                      {errors.city && touched.city ? (
                        <div className='validation-error col-12 text-right '>{errors.city}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="state">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        State:
                      </label>
                      <div className="col-sm-7">
                        <Field name="state"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.state}
                        />
                      </div>
                      {errors.state && touched.state ? (
                        <div className='validation-error col-12 text-right '>{errors.state}</div>
                      ) : null}
                    </div>

                    <div className="form-group row" id="zip">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Zip:
                      </label>
                      <div className="col-sm-7">
                        <Field name="zip"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.zip}
                          maxLength="5"
                        />
                      </div>
                      {errors.zip && touched.zip ? (
                        <div className='validation-error col-12 text-right '>{errors.zip}</div>
                      ) : null}
                    </div>
                    
                    <div className="actions text-center">
                      <Button onClick={_ => {
                        window.scrollTo(0, 0);
                        changeRegisterIndex(registerIndex - 1)
                      }} type="button" className="form-btn float-left"><BsChevronLeft /> Back</Button>
                      <Button type="submit" className="form-btn float-right">Next <BsChevronRight /></Button>
                    </div>
                  </Form>)}
                </Formik>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default CompanyInfoForm;