import { connect } from 'react-redux';
import RegisterComponent from './RegisterComponent';
import RegisterTruckOperations  from './duck/operations';
const mapStateToProps = currentState => {
  const {
    registerTruck: {
      showEndTime,
      startTime,
      endTime,
      parkingDayType,
      startDate,
      slotMsg,
      endDate,
      email_address,
      first_name,
      last_name,
      mobile_number,
      company_name,
      company_url,
      address_1,
      address_2,
      city,
      state,
      zip,
      
      driver,
      driver_name,
      driver_licence_number,
      driver_licence_expiry,
      driver_address,

      truck,
      truck_register_number,
      truck_licence_expiry,
      truck_register_state,
      truck_color,

      insurance,
      insurance_company,
      insurance_policy_number,
      insurance_expiry,
      
      signature,
      agreeTerms,
      slotAvailable,
      registerIndex,
      loading,
      registrationSuccess,
      message,
      totalSteps,
      vehicleIdentification,
      vin,
      vehicleType,
      vehiclePrice,
      noOfDays
    }
  } = currentState;
  return {
    showEndTime,
    startTime,
    endTime,
    parkingDayType,
    startDate,
    endDate,
    slotMsg,
    email_address,
    first_name,
    last_name,
    mobile_number,
    company_name,
    company_url,
    address_1,
    address_2,
    city,
    totalSteps,
    state,
    zip,
    
    driver,
    driver_name,
    driver_licence_number,
    driver_licence_expiry,
    driver_address,
    
    truck,
    truck_register_number,
    truck_licence_expiry,
    truck_register_state,
    truck_color,
    
    insurance,
    insurance_company,
    insurance_policy_number,
    insurance_expiry,
    
    signature,
    agreeTerms,
    slotAvailable,
    registerIndex,
    loading,
    registrationSuccess,
    message,
    vehicleIdentification,
    vin,
    vehicleType,
    vehiclePrice,
    noOfDays
  };
}

const mapDispatchToProps = dispatch => {
  const onFieldChange = (value, field) => dispatch(RegisterTruckOperations.setFieldsAction(value, field));
  const changeRegisterIndex = registerIndex => dispatch(RegisterTruckOperations.changeRegisterIndexAction(registerIndex));
  const changeTotalSteps = totalSteps => dispatch(RegisterTruckOperations.changeTotalStepsAction(totalSteps));
  const getAvailableDates = dateRange => dispatch(RegisterTruckOperations.getAvailableDates(dateRange));
  const registerTruck = _ => dispatch(RegisterTruckOperations.registerTruck());
  const submitCompanyInfo = values => dispatch(RegisterTruckOperations.submitCompanyInfo(values));
  const submitPersonalInfo = values => dispatch(RegisterTruckOperations.submitPersonalInfo(values));
  const submitDriverInfo = values => dispatch(RegisterTruckOperations.submitDriverInfo(values));
  const submitTruckInfo = values => dispatch(RegisterTruckOperations.submitTruckInfo(values));
  const submitInsuranceInfo = values => dispatch(RegisterTruckOperations.submitInsuranceInfo(values));

  
  return {
    onFieldChange,
    changeRegisterIndex,
    changeTotalSteps,
    getAvailableDates,
    registerTruck,
    submitCompanyInfo,
    submitPersonalInfo,
    submitDriverInfo,
    submitTruckInfo,
    submitInsuranceInfo
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterComponent);