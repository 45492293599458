import axios from 'axios';
import CONFIG from '../../service-config';

const instance = axios.create({
  baseURL: CONFIG.BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const uploadInstance = axios.create({
  baseURL: CONFIG.BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

const ApiService = {
  getData: (url) =>
    instance({
      'method': 'GET',
      'url': url
    }),
  postData: (url, data) =>
    instance({
      'method': 'POST',
      'url': url,
      'data': data
    }),
  postFile: (url, data) =>
    uploadInstance({
      'method': 'POST',
      'url': url,
      'data': data
    })
}
export default ApiService;
