

const setObject = (_key, _data) => {
    localStorage.setItem(_key, (JSON.stringify(_data)));
   };
   
   const getObject = (_key) => {
       let _data = localStorage.getItem(_key);
   
       try {
           return JSON.parse(_data);
       } catch (e) {
           return {};
       }
   };
   
   const purgeData = (_key) => {
       return localStorage.removeItem(_key);
   }
   
   const clear = () => {
       return localStorage.clear();
   }
   
   const StorageService = {
       setObject,
       getObject,
       purgeData,
       clear,
   };
   
   export default StorageService;