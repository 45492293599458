import React, { Component, Fragment } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

class InsuranceInfoForm extends Component {
  render() {
    const {
      registerIndex,
      changeRegisterIndex,
      submitInsuranceInfo,
      onFieldChange,
      totalSteps,
      insurance
    } = this.props;
    const InsuranceInfoSchema = !insurance ? Yup.object().shape({
      insurance_company: Yup.string()
        .required('Please enter this field'),
      insurance_policy_number: Yup.string()
        .required('Please enter this field'),
      insurance_expiry: Yup.date()
        .required('Please enter this field'),
    }) : Yup.object().shape({});
    const resetTouched = (touched, values) => {
      Object.keys(key => {
        if (key !== 'insurance') {
          touched[key] = false;
        }
      })
      values['insurance_company'] = '';
      values['insurance_policy_number'] = '';
      values['insurance_expiry'] = '';
    }
    return (
      <Fragment>
        <Row>
          <Col sm="12">
            <h3 className="mb-4"><span className='step-count'>{`Step ${registerIndex}/${totalSteps} - `}</span> Insurance Information:</h3>
            <div className="form">
              <Formik
                initialValues={{
                  insurance_company: '',
                  insurance_policy_number: '',
                  insurance_expiry: ''
                }}
                validationSchema={InsuranceInfoSchema}
                onSubmit={values => {
                  submitInsuranceInfo(values);
                  window.scrollTo(0, 0);
                  changeRegisterIndex(registerIndex + 1)
                }}
              >
                {({ handleChange, handleBlur, errors, touched, values }) => (
                  <Form noValidate>
                    <div className="form-group row" id="insurance">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Upload Insurance Policy:
                      </label>
                      <div className="col-sm-7">
                        <div className="row">
                          <div className="col-10">
                            <input name="insurance"
                              type="file"
                              className="form-control"
                              onChange={e => {
                                handleChange(e)
                                onFieldChange(e.target.files[0], 'insurance');
                                resetTouched(touched, values);
                              }}
                              disabled={values.insurance_company || values.insurance_policy_number || values.insurance_expiry}
                              onBlur={handleBlur}
                              value={values.insurance}
                              accept="image/*"
                            />
                          </div>
                          <div className="col-2">
                            <button disabled={!insurance} type="button" className="btn btn-light" onClick={_ => {
                              values.insurance = '';
                              onFieldChange(null, 'insurance');
                            }}>Clear</button>
                          </div>
                        </div>
                        <small className="d-block mt-2">docx, png, jpg and pdf 2MB max</small>
                      </div>
                      {errors.insurance && touched.insurance ? (
                        <div className='validation-error col-12 text-right'>{errors.insurance}</div>
                      ) : null}
                    </div>
                    <div className="or-separator">(OR)</div>
                    <div className="row mb-3">
                      <label className="form-label col-sm-12">
                        Enter the following fields with required information.
                      </label>
                    </div>
                    <div className="form-group row" id="insurance_company">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Insurance Company:
                      </label>
                      <div className="col-sm-7">
                        <Field name="insurance_company"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          disabled={insurance}
                          onBlur={handleBlur}
                          value={values.insurance_company}
                        />
                      </div>
                      {errors.insurance_company && touched.insurance_company ? (
                        <div className='validation-error col-12 text-right'>{errors.insurance_company}</div>
                      ) : null}
                    </div>
                    <div className="form-group row" id="insurance_policy_number">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Insurance Policy Number:
                      </label>
                      <div className="col-sm-7">
                        <Field name="insurance_policy_number"
                          type="text"
                          className="form-control"
                          disabled={insurance}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.insurance_policy_number}
                        />
                      </div>
                      {errors.insurance_policy_number && touched.insurance_policy_number ? (
                        <div className='validation-error col-12 text-right'>{errors.insurance_policy_number}</div>
                      ) : null}
                    </div>
                    <div className="form-group row" id="insurance_expiry">
                      <label className="col-form-label mandatory form-label col-sm-5">
                        Insurance Expiry Date:
                      </label>
                      <div className="col-sm-7">
                        <Field name="insurance_expiry"
                          type="date"
                          className="form-control"
                          onChange={handleChange}
                          disabled={insurance}
                          onBlur={handleBlur}
                          value={values.insurance_expiry}
                        />
                      </div>
                      {errors.insurance_expiry && touched.insurance_expiry ? (
                        <div className='validation-error col-12 text-right'>{errors.insurance_expiry}</div>
                      ) : null}
                    </div>
                    <div className="actions mt-3 text-center">
                      <Button onClick={_ => {
                        window.scrollTo(0, 0);
                        changeRegisterIndex(registerIndex - 1)
                      }} type="button" className="form-btn float-left"><BsChevronLeft /> Back</Button>
                      <Button type="submit" className="form-btn float-right">Next <BsChevronRight /></Button>
                    </div>
                  </Form>)}
              </Formik>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default InsuranceInfoForm;