import Actions from './actions';
import ApiService from '../../utils/api';
import { format } from 'date-fns';

const { setFields, changeRegisterIndex, changeTotalSteps, setSlotAvailablity,
  showLoader, registrationSuccess, submitCompanyInfo,
  submitPersonalInfo, submitDriverInfo, submitTruckInfo, submitInsuranceInfo } = Actions;

const setFieldsAction = setFields;
const changeRegisterIndexAction = changeRegisterIndex;
const changeTotalStepsAction = changeTotalSteps;

const getAvailableDates = range => dispatch => {
  dispatch(showLoader(true));
  const {
    startDate,
    endDate
  } = range;
  var form_data = new FormData();
  const item = { from_date: format(startDate, 'yyyy-MM-dd'), to_date: format(endDate, 'yyyy-MM-dd') };
  for (var key in item) {
    form_data.append(key, item[key]);
  }
  ApiService.postData('/parkingslot', form_data)
    .then(res => {
      if (res.data.status) {
        return dispatch(setSlotAvailablity(res.data.status, res.data.data))
      }
      return dispatch(setSlotAvailablity(false))
    });
}

const registerTruck = _ => (dispatch, getState) => {
  let {
    registerTruck: {
      startTime,
      endTime,
      parkingDayType,
      startDate,
      endDate,
      email_address,
      first_name,
      last_name,
      mobile_number,
      company_name,
      company_url,
      address_1,
      address_2,
      city,
      state,
      zip,
      driver,
      driver_name,
      driver_licence_number,
      driver_licence_expiry,
      driver_address,
      truck,
      truck_register_number,
      truck_licence_expiry,
      truck_register_state,
      truck_color,
      insurance_company,
      insurance_policy_number,
      insurance_expiry,
      insurance,
      vehicleIdentification,
      vin,
      vehicleType,
      vehiclePrice,
      noOfDays
    }
  } = getState();
  dispatch(showLoader(true));

  if (driver) {
    driver_licence_expiry = '';
  }
  if (insurance) {
    insurance_expiry = '';
  }
  if (truck) {
    truck_licence_expiry = '';
  }

  var form_data = new FormData();

  let item = {
    from_time:startTime,
    to_time:endTime,
    parking_day_type:parkingDayType,
    from_date: format(startDate, 'yyyy-MM-dd'),
    to_date: format(endDate, 'yyyy-MM-dd'),
    email_address,
    first_name,
    last_name,
    mobile_number,
    company_name,
    company_url,
    address_1,
    address_2,
    city,
    state,
    zip,
    truck_color,
    signature: `${first_name} ${last_name}`,
    insurance_company,
    insurance_policy_number,
    insurance_expiry,
    insurance,
    driver,
    driver_name,
    driver_licence_number,
    driver_licence_expiry,
    driver_address,
    truck,
    truck_register_number,
    truck_licence_expiry,
    truck_register_state,
    vehicleIdentification,
    vin,
    vehicleType,
    vehiclePrice,
    noOfDays
  };


  for (var key in item) {
    form_data.append(key, item[key]);
  }


  for (const pair of form_data.entries()) {
    console.log(`${pair[0]}, ${pair[1]}`);
  }

  
  ApiService.postFile('/register', form_data)
    .then(res => {
      dispatch(showLoader(false));
      console.log(res)
      if (res.data.status) {
        dispatch(registrationSuccess({ registrationSuccess: res.data.status, message: res.data.data }));
        window.setTimeout(_ => {
          //window.location.replace(res.data.payment_url);
          window.location = res.data.payment_url;

        }, 3000)
      } else {
        dispatch(registrationSuccess({ registrationSuccess: res.data.status, message: res.data.message }));
      }
    });
}

const operations = {
  setFieldsAction,
  changeRegisterIndexAction,
  changeTotalStepsAction,
  getAvailableDates,
  registerTruck,
  submitCompanyInfo,
  submitDriverInfo,
  submitTruckInfo,
  submitPersonalInfo,
  submitInsuranceInfo
};

export default operations;