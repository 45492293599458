import storageService from "./storage.service";



const authorizationToken = () => {
  let _auth = storageService.getObject("authUser");
  let _token = null;

  try {
    _token = _auth.token;
  } catch (error) {
    _token = null;
  }
  return "Bearer " + _token;
};


const ConfigService = {
 authorizationToken
};

export default ConfigService;
