import React, { Component, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { connect } from 'react-redux';
import './App.scss';

// PAGES
import NavBarComponent from './common/NavBar';
import RegisterContainer from './Register/RegisterContainer';
import AdminLogin from './Auth/AdminLogin';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import UserLists from './User/UserLists';
import UserDetails from './User/UserDetails';

class App extends Component {
  render() {
    return (
      <Router>
           
        <NavBarComponent />
        <Suspense fallback="Loading...">
          <div className="page-content">
         
            <Switch>
              <Route exact path="/admin_login" component={AdminLogin} />
              <Route exact path="/forgot_password" component={ForgotPassword} />
              <Route exact path="/reset_password/:token" component={ResetPassword} />
              <Route exact path="/user_list" component={UserLists} />
              <Route exact path="/user_details/:id" component={UserDetails} />


              <Route exact path="/" component={RegisterContainer} />
              <Route exact path="/register-truck" component={RegisterContainer} />
              <Route path="*" component={RegisterContainer}/>
              
            </Switch>
            
          </div>
        </Suspense>
      </Router>
    );
  }
}


const mapStateToProps = state => ({
  ...state
});
const mapDispatchToProps = _ => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(App);
